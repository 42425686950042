import Service from "../service";
import AuthService from "../AuthService";
const url = "course";
const course = {
  get(data) {
    return Service.get(url, { params: data });
  },
  courseHistory(data) {
    return AuthService.get("get-course-history", { params: data });
  },
  show(model) {
    return Service.get(`${url}/${model.courseId}`, {
      params: { user_id: model.user_id },
    });
  },
  courseHome() {
    return Service.get(`course-home`);
  },
  register(payload) {
    return AuthService.post(`${url}`, payload);
  },
  certificate(payload) {
    return AuthService.get(`certificate-template`, { params: payload });
  },
  checkSubsriptionYearly(payload) {
    const { user_id,course_id } = payload;
    return AuthService.get(`/check-payment-status?user_id=${user_id}&course_id=${course_id}`);
  },
  storePayment(payload) {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return AuthService.post(`/payment`, payload, options);
  }
};
export default course;
