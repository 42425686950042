var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{nativeOn:{"mouseleave":function($event){return _vm.mouseLeave.apply(null, arguments)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$route.name !== 'ChatView' &&
      _vm.$route.name !== 'ForgotPassword' &&
      _vm.$route.name !== 'ResetPassword' &&
      _vm.$route.name !== 'Login' &&
      _vm.$route.name !== 'Register'
    ),expression:"\n      $route.name !== 'ChatView' &&\n      $route.name !== 'ForgotPassword' &&\n      $route.name !== 'ResetPassword' &&\n      $route.name !== 'Login' &&\n      $route.name !== 'Register'\n    "}],staticClass:"navigation__chat"}),_c('v-main',[(
        _vm.$route.name !== 'Login' &&
        _vm.$route.name !== 'Register' &&
        _vm.$route.name !== 'ForgotPassword' &&
        _vm.$route.name !== 'ResetPassword'
      )?_c('Header'):_vm._e(),_c('div',{staticClass:"min-height"},[_c('router-view',{key:_vm.$route.fullPath})],1),_c('Footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }