<template>
  <v-app @mouseleave.native="mouseLeave">
    <!-- Chat -->
    <div
      class="navigation__chat"
      v-show="
        $route.name !== 'ChatView' &&
        $route.name !== 'ForgotPassword' &&
        $route.name !== 'ResetPassword' &&
        $route.name !== 'Login' &&
        $route.name !== 'Register'
      "
    >
      <!-- <v-btn
        to="/chat"
        color="#4090ff"
        height="90"
        width="90"
        class="pa-4 btn__chat"
      >
        <div>
          <div class="d-flex justify-center">
            <v-img
              width="34"
              height="34"
              contain
              aspect-ratio="1"
              src="./assets/icon/chat_icon_white.png"
            ></v-img>
          </div>
          <div class="white--text font-12">ปรึกษา Case</div>
        </div>
      </v-btn> -->
      
    </div>

    <v-main>
      <Header
        v-if="
          $route.name !== 'Login' &&
          $route.name !== 'Register' &&
          $route.name !== 'ForgotPassword' &&
          $route.name !== 'ResetPassword'
        "
      ></Header>
      <div class="min-height">
        <router-view :key="$route.fullPath" />
      </div>

      <!-- <div :class="hideCookie ? 'hide-cookie' : 'cookie'" v-if="isCookie">
        <div class="cookie-card px-12">
          <p class="mt-2">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, hic
            voluptatum repudiandae deleniti, recusandae fuga deserunt ratione
            adipisci officiis blanditiis iure sed, quo harum dolores quidem id
            officia consequatur quae!
          </p>
          <div class="d-flex justify-end">
            <v-btn
              dark
              tile
              depressed
              color="#fff"
              class="text-capitalize"
              @click="acceptCookie"
            >
              <span style="color: #0066b3"> I Agree </span>
            </v-btn>
          </div>
        </div>
      </div> -->

      <Footer></Footer>
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/Layout/Header.vue";
import Footer from "./components/Layout/Footer.vue";
import SERVICE from "./services/index";

export default {
  components: {
    Header,
    Footer,
  },
  name: "App",
  data() {
    return {
      isCookie: true,
      hideCookie: false,
    };
  },
  mounted() {
    this.$fbCustomerChat.setOptions({
      theme_color: "#4090FF",
    });
  },
  created() {
    // ตรวจสอบ cookie
    if (this.getCookie("cookie:accepted")) {
      this.isCookie = false;
    } else {
      this.isCookie = true;
    }
  },
  computed: {
    access_token() {
      return this.$store.state.auth.access_token;
    },
    questionnaire() {
      return this.$store.state.auth.questionnaire;
    },
    dialogQuestionnaire() {
      return this.$store.state.auth.dialogQuestionnaire;
    },
    isLogout() {
      return this.$store.state.auth.isLogout;
    },
  },
  methods: {
    mouseLeave() {
      // if (!this.getCookie("questionnaire")) {
      //   this.$store.commit("auth/setIsQuestionnaire", true);
      // }
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    // COOKIE ******************
    acceptCookie() {
      // hide cookie
      this.hideCookie = true;
      // set expires cookies 6 month after accepted
      this.setCookie("cookie:accepted", true, 182);
      setTimeout(() => {
        this.isCookie = false;
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Prompt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #ffffff 0%, #edf0f6 100%);
}
.min-screen-support {
  position: relative;
  overflow: auto;
}
.navigation__chat {
  position: fixed;
  top: 35%;
  right: 0;
  z-index: 7;
  .btn__chat {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.v-dialog {
  border-radius: 30px;
}
.v-dialog__content {
  // z-index: 9999 !important;
}
.cookie {
  position: fixed;
  bottom: 0;
  z-index: 2;
  animation: 1s ease-out 0s 1 slideInFromDown;
}
.hide-cookie {
  position: fixed;
  bottom: 0;
  z-index: 2;
  animation: 1s ease-out 0s 1 slideInFromUp;
}
.cookie-card {
  background-color: #4090fff2;
  color: #ffffff;
  padding: 10px;
  widows: 90%;
}

@keyframes slideInFromDown {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(150%);
  }
}
</style>
