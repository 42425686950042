import AuthService from "../AuthService";
import Service from "../service";

const auth = {
  signin(data) {
    return Service.post("/signin", data).then((res) => res);
  },
  signout() {
    return AuthService.get(`/logout`).then((res) => res);
  },
  changePassword(data) {
    return AuthService.post(`/change-password`, data).then((res) => res);
  },
  saveQuestionaire(data) {
    return AuthService.post(`/save-questionaire`, data).then((res) => res);
  }
};

export default auth;
