<template>
  <div class="bg-footer">
    <v-container class="mw-1150">
      <v-layout justify-center>
        <v-flex xs12 sm10 md12 class="py-6 py-sm-6 py-md-0">
          <v-row justify="space-between" class="align-center">
            <v-col lg="7" md="8" sm="12" cols="12">
              <v-layout align-center wrap>
                <v-flex xs9 sm6 md5>
                  <v-layout>
                    <v-flex xs4>
                      <v-img
                        class="ma-1"
                        alt="logo"
                        src="../../assets/logo/logo1.png"
                        aspect-ratio="1"
                        max-height="60"
                        max-width="60"
                        contain
                      ></v-img>
                    </v-flex>
                    <!-- <v-flex xs4>
                      <v-img
                        class="ma-1"
                        alt="logo"
                        src="../../assets/logo/logo2.png"
                        aspect-ratio="1"
                        max-height="60"
                        max-width="60"
                        contain
                      ></v-img>
                    </v-flex> -->
                    <v-flex xs4>
                      <v-img
                        class="ma-1"
                        alt="logo"
                        src="../../assets/logo/logo-white.png"
                        aspect-ratio="1"
                        max-height="60"
                        max-width="60"
                        contain
                      ></v-img>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md7 class="mt-6 mt-sm-0">
                  <div
                    :class="
                      $vuetify.breakpoint.xs
                        ? 'font-22 font-w-400'
                        : 'font-14 font-w-400'
                    "
                  >
                    ระบบให้คำปรึกษาและพัฒนาผู้ปฏิบัติงานคุ้มครองเด็กและผู้ถูกกระทำด้วยความรุนแรงในครอบครัว
                  </div>
                  <div
                    style="color: #6dcdec"
                    :class="
                      $vuetify.breakpoint.xs
                        ? 'font-22 font-w-400'
                        : 'font-14 font-w-400'
                    "
                  >
                    OSCC.Consulting@gmail.com
                  </div>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col lg="5" md="4" sm="12" cols="12" class="mt-8 mt-sm-0">
              <v-layout justify-space-between>
                <v-spacer />
                <v-flex xs12 md10 lg7 class="d-flex justify-end">
                  <v-layout wrap>
                    <v-flex xs12 sm6 class="mb-7 mb-sm-0">
                      <div
                        class="cursor-pointer"
                        :class="
                          $vuetify.breakpoint.xs
                            ? 'font-22 font-w-400'
                            : 'font-14 font-w-400'
                        "
                        @click="$router.push('/course')"
                      >
                        หลักสูตรอบรม
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 class="mb-7 mb-sm-0">
                      <div
                        class="cursor-pointer"
                        :class="
                          $vuetify.breakpoint.xs
                            ? 'font-22 font-w-400'
                            : 'font-14 font-w-400'
                        "
                        @click="$router.push('/about')"
                      >
                        เกี่ยวกับเรา
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 class="mb-7 mb-sm-0">
                      <div
                        class="cursor-pointer"
                        :class="
                          $vuetify.breakpoint.xs
                            ? 'font-22 font-w-400'
                            : 'font-14 font-w-400'
                        "
                        @click="$router.push('/event-news')"
                      >
                        กิจกรรมและข่าวสาร
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 class="mb-7 mb-sm-0">
                      <div
                        class="cursor-pointer"
                        :class="
                          $vuetify.breakpoint.xs
                            ? 'font-22 font-w-400'
                            : 'font-14 font-w-400'
                        "
                        @click="$router.push('/faq')"
                      >
                        คำถามที่พบบ่อย
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6>
                      <div
                        class="cursor-pointer"
                        :class="
                          $vuetify.breakpoint.xs
                            ? 'font-22 font-w-400'
                            : 'font-14 font-w-400'
                        "
                      >
                        <a
                          class="policy"
                          style="color: #fff"
                          :href="`${banner_path}PrivacyPolicy.pdf`"
                          target="_blank"
                        >
                          นโยบายของเว็บไซต์
                        </a>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
      <!-- <v-row v-else class="align-center px-5">
        <v-col cols="12" class="px-2">
          <div class="footer-topic">
            ระบบให้คำปรึกษาและพัฒนาผู้ปฏิบัติงานคุ้มครองเด็กและผู้ถูกกระทำด้วยความรุนแรงในครอบครัว
          </div>
        </v-col>
        <v-col cols="12" class="px-2">
          <div class="footer-topic">อีเมลเว็บไซด์</div>
          <ul>
            <li>OSCC.Consulting@gmail.com</li>
          </ul>
        </v-col>
        <v-col cols="12" class="px-2">
          <div class="footer-topic">หลักสูตรอบรม</div>
          <ul>
            <li>สุขภาพจิต</li>
            <li>สุขภาพทั่วไป</li>
          </ul>
        </v-col>
        <v-col cols="12" class="px-2">
          <div class="footer-topic">ข่าวสาร</div>
          <ul>
            <li>ข่าวประชาสัมพันธ์</li>
            <li>ข่าวสารทั่วไป</li>
          </ul>
        </v-col>
        <v-col cols="12" class="px-2">
          <div class="footer-topic">
            เกี่ยวกับเรา<br />
            คำถามที่พบบ่อย
          </div>
        </v-col>
        <v-col cols="12">
          <v-layout>
            <v-flex shrink mx-3>
              <v-img
                class="ma-1"
                alt="logo"
                src="../../assets/logo/logo1.png"
                width="60"
                height="60"
                aspect-ratio="1"
                contain
              ></v-img>
            </v-flex>
            <v-flex shrink mx-3>
              <v-img
                class="ma-1"
                alt="logo"
                src="../../assets/logo/logo2.png"
                width="60"
                height="60"
                aspect-ratio="1"
                contain
              ></v-img>
            </v-flex>
            <v-flex shrink mx-3>
              <v-img
                class="ma-1"
                alt="logo"
                src="../../assets/logo/logo-white.png"
                width="60"
                height="60"
                aspect-ratio="1"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
import logo1 from "../../assets/logo/footer/logo1.png";
import logo2 from "../../assets/logo/footer/logo2.png";
import logo3 from "../../assets/logo/footer/logo3.png";
import { banner_path } from "../../util/Constant";
export default {
  data() {
    return {
      logo1: logo1,
      logo2: logo2,
      logo3: logo3,
      banner_path: banner_path,
    };
  },
};
</script>

<style>
.footer-topic {
  font-weight: 400;
  font-size: 20px;
}
li {
  font-weight: 300 !important;
  font-size: 16px !important;
}

.policy:hover {
  color: #6dcdec !important;
  text-decoration: none;
}
</style>
